import { Map } from 'immutable';
import urlSlug from 'url-slug'
import fileDownload from 'js-file-download'

export function clearToken() {
  localStorage.removeItem('id_token');
}

export function getToken() {
  try {
    const idToken = sessionStorage.getItem('id_token');
    return new Map({ idToken });
  } catch (err) {
    clearToken();
    return new Map();
  }
}

export function arrayEqual(array1, array2) {
  return array1.sort().toString() === array2.sort().toString();
}

export function number(num) {
  return (num > 9 ? '' + num : '0' + num);
}

export function replaceSpecialMsgChars(text) {
  text = text.replace(/\*([^*]+)\*/g, "<b>$1</b>");
  return text.replace(/\n/g, '<br/>');
}

export function renderDate(date) {
  if(typeof date !== 'undefined') {
    const month = number(date.getUTCMonth() + 1);
    const day = number(date.getUTCDate());
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  }
}

export function timeDifference(givenTime) {
  givenTime = new Date(givenTime);
  const milliseconds = new Date().getTime() - givenTime.getTime();
  const numberEnding = (number) => {
    return number > 1 ? 's' : '';
  };
  const getTime = () => {
    let temp = Math.floor(milliseconds / 1000);
    const years = Math.floor(temp / 31536000);
    if (years) {
      const month = number(givenTime.getUTCMonth() + 1);
      const day = number(givenTime.getUTCDate());
      const year = givenTime.getUTCFullYear() % 100;
      return `${day}-${month}-${year}`;
    }
    const days = Math.floor((temp %= 31536000) / 86400);
    if (days) {
      if (days < 28) {
        return days + ' day' + numberEnding(days);
      } else {
        const months = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ];
        const month = months[givenTime.getUTCMonth()];
        const day = number(givenTime.getUTCDate());
        return `${day} ${month}`;
      }
    }
    const hours = Math.floor((temp %= 86400) / 3600);
    if (hours) {
      return `${hours} hour${numberEnding(hours)} ago`;
    }
    const minutes = Math.floor((temp %= 3600) / 60);
    if (minutes) {
      return `${minutes} minute${numberEnding(minutes)} ago`;
    }
    return 'a few seconds ago';
  };
  return getTime();
}

export function daysDifference(givenTime) {
  givenTime = new Date(givenTime);
  const milliseconds = new Date().getTime() - givenTime.getTime();
  let temp = Math.floor(milliseconds / 1000);
  return Math.floor((temp %= 31536000) / 86400);
}

export function displayDate(givenTime) {
    givenTime = (typeof givenTime != 'undefined') ? new Date(givenTime) : new Date();
    const month = number(givenTime.getUTCMonth() + 1);
    const day = number(givenTime.getUTCDate());
    const year = givenTime.getUTCFullYear();
    return `${day}/${month}/${year}`;
}

export function displayDateTime(givenTime, full=false) {
  givenTime = (typeof givenTime !== 'undefined') ? new Date(givenTime) : new Date();
  const month = number(givenTime.getUTCMonth() + 1);
  const day = number(givenTime.getUTCDate());
  const year = (full) ? givenTime.getUTCFullYear() : givenTime.getUTCFullYear() % 100;
  const hour = number(givenTime.getHours());
  const minutes = number(givenTime.getMinutes());
  return `${day}/${month}/${year} ${hour}:${minutes}`;
}

export function formatDate(value) {
    //20/04/2023
    if(testEmpty(value, '') !== '') {
      const d = value.slice(0, 2);
      const m = value.slice(3, 5);
      const y = value.slice(6, 10);
      return `${y}/${m}/${d}`;
    }
    return ''
}

export function formatDateTime(value) {
    //20/04/2023 10:50
    const d = value.slice(0, 2);
    const m = value.slice(3, 5);
    const y = value.slice(6, 10);
    const h = value.slice(11, 16);
    return `${y}/${m}/${d} ${h}`;
}

export function displayDateTimeFromAmericamFormat(value, full=false) {
    //2024-04-23 07:30:29
    if(testEmpty(value, '') !== '') {
      const y = value.slice(0, 4);
      const m = value.slice(5, 7);
      const d = value.slice(8, 10);
      const h = value.slice(11, 13);
      const M = value.slice(14, 16);
      const s = value.slice(17, 19);
      return (full) ? `${d}/${m}/${y} ${h}:${M}:${s}` : `${d}/${m}/${y}`;
    }
    else 
      return '';
}

export function formatTime(value) {
    //20/04/23 10:50
    const h = value.slice(9, 14);
    return `${h}`;
}

export function stringToInt(value, defValue = 0) {
  if (!value)
    return 0;
  else if (!isNaN(value))
    return parseInt(value, 10);
  return defValue;
}
export function stringToPosetiveInt(value, defValue = 0) {
  const val = stringToInt(value, defValue);
  return val > -1 ? val : defValue;
}
export function displayCEP(value) {
    if(typeof value != 'undefined') {
      const start = value.slice(0, 5);
      const end = value.slice(5, 8);
      return start+'-'+end;
    }
    return '';
}

export function formatPhoneNumber(value) {
    let formattedNumber = '';
    if(typeof value != 'undefined') {

      const { length } = value;
      const regex = () => value.replace(/[^0-9\.]+/g, "");
      
      const areaCode = () => `(${regex().slice(0, 2)})`;
      
      const trailer = (start, end=regex().length) => `${regex().slice(start, end)}`;
      
      const firstSix = () => `${areaCode()} ${trailer(2, 7)}`;
      
      if (length === 11)
        formattedNumber = `${firstSix()}-${trailer(7)}`;
      else if (length === 10)
        formattedNumber = `${`${areaCode()} ${trailer(2, 6)}`}-${trailer(6)}`;
    }
    return formattedNumber;
}

export function capitalizeText(str) {
  const titleCase = str
    .toLowerCase()
    .split(' ')
    .map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');

  return titleCase;
}

export function testEmpty(text, s='----------') {
    return (valueIsNull(text) === '') ? s : text;
}

const defaultOptions = {
  significantDigits: 2,
  thousandsSeparator: '.',
  decimalSeparator: ',',
  symbol: ''
}

export function formatMoney(value, options) {
  value = (typeof value !== 'number') ? parseFloat(value, 10) : value
  if(isNaN(value))
    value = 0.0
  options = { ...defaultOptions, ...options }
  value = value.toFixed(options.significantDigits)

  const [currency, decimal] = value.split('.')
  return `${options.symbol} ${currency.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    options.thousandsSeparator
  )}${options.decimalSeparator}${(decimal) ? decimal : ''}`
}

export function maxDigits(n, d=2) {
  if(testEmpty(n, '') !== '') {
      let a = new String(n).split('.');
      if(typeof a[1] !== 'undefined')
          if(a[1].length >= d+1) {
              return `${a[0]}.${a[1].slice(0, d)}`
          }
          else
              return `${a[0]}.${a[1].slice(0, d)}`
      else
          return `${a[0]}.00`
  }
}

export function minDigits(n, d=2) {
  if(testEmpty(n, '') !== '') {
    let a = new String(n).split('.');
    if(typeof a[1] !== 'undefined' && a[1].length === 1) {
      for (let i = 0; i < d-1; i++) {
        a[1] += '0';
      }
    }
    return a.join('.');
  }
}

export function convertToNumber(txt, d=2) {
  return parseFloat(parseFloat((txt+'').replace('.', '').replace(',', '.'), 10).toFixed(d));
}

export function validadePhone(txt) {
  var phoneno = /^\(?([0-9]{2})\)?[-. ]?([0-9]{5})[-. ]?([0-9]{4})$/;
  if(txt.match(phoneno)) { return true; }

  phoneno = /^\(?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
  if(txt.match(phoneno)) { return true; }

  return false;
}

export function validadeEmail(email) {
  return String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
}

export function onlyNumber(element) {
  return (typeof element !== 'undefined' && element !== null) ? element.replace(/\D/g, "") : '';
}

export function isLocalhost() {
  return (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1");
}

export function getBaseUrl() {
  return isLocalhost() ? 'http://localhost:3001' : 'https://app1.p7.com.br';
}

export function getChatUrl() {
  return isLocalhost() ? 'http://localhost:3005' : 'https://app2.p7.com.br';
}

export function getWhatsIntegrationUrl() {
  return isLocalhost() ? 'http://localhost:3333' : 'https://app3.p7.com.br';
}

export function getCatalogUrl(config) {
  if(config.hasDomain === 'S')
    return (config.domain.indexOf('http') !== -1) ? config.domain : 'https://'+config.domain;
  else 
    return (isLocalhost() ? 'http://localhost:3007' : 'https://app6.p7.com.br') + '/' + config.slug;
}

export function findObjIntoArrayByProp(arr, prop, val) {
  return arr.findIndex(obj => obj[prop] === val);
}

export async function getAddrresData(cep) {
  return await fetch('https://viacep.com.br/ws/'+onlyNumber(cep)+'/json/')
    .then(res => res.json())
    .then((res) => {
      if (res.hasOwnProperty("erro")) {
            alert('Cep não existente');
      } else {
        return res;
      }
    })
    .catch(error => error);
}

export async function getBanksList() {
  return await fetch('https://brasilapi.com.br/api/banks/v1')
    .then(res => res.json())
    .then((res) => {
        return res;
    })
    .catch(error => error);
}

export function utf8_to_b64(str) {
  return window.btoa(unescape(encodeURIComponent(str)));
}

export function convertPercentageLimit(v) {
  v = testEmpty(v, '0,00')
  v = (typeof v === 'string') ? (v+'').replace(',', '.') : v
  if(v > 100 || v < 0){
    v = (v > 100) ? 100 : v
    v = (v < 0) ? 0 : v
  } else {
    v = v.replace('.', ',')
  }
  return v
}

export function formatStringToFloatMoney(v, d=2) {
  return parseFloat((testEmpty(v, '0,00')+'').replace(',', '.')).toFixed(d).replace('.', ',');
}

export function downloadFileFromURL(url, name, formato) {
  var request = new XMLHttpRequest();
  request.open('GET', url, true);
  request.responseType = 'blob';
  request.onload = function() {
      fileDownload(request.response, name + '.' + formato)
  };

  request.send();
}

export function b64_to_utf8(str) {
  return decodeURIComponent(escape(window.atob(str)));
}

export function convertToBrDateTime(utcDateTime) {
  const date = new Date(utcDateTime);
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: 'America/Sao_Paulo'
  };

  return date.toLocaleString('pt-BR', options);
}

export function textLimit(t, l, i='...') {
  let a = testEmpty(t, '');
  return ((a.length+3) >= l) ? a.substr(0, l)+i : a;
}

export function formatarDocument(documento) {
  const tamanho = documento.length;
  if (tamanho === 11) {
    const parte1 = documento.substr(0, 3);
    const parte2 = documento.substr(3, 3);
    const parte3 = documento.substr(6, 3);
    const parte4 = documento.substr(9, 2);
    return `${parte1}.${parte2}.${parte3}-${parte4}`;
  } else if (tamanho === 14) {
    const parte1 = documento.substr(0, 2);
    const parte2 = documento.substr(2, 3);
    const parte3 = documento.substr(5, 3);
    const parte4 = documento.substr(8, 4);
    const parte5 = documento.substr(12, 2);
    return `${parte1}.${parte2}.${parte3}/${parte4}-${parte5}`;
  } else if (tamanho === 8) {
    const parte1 = documento.substr(0, 5);
    const parte2 = documento.substr(5, 3);
    return `${parte1}-${parte2}`;
  } else {
    return documento;
  }
}

export function valueIsNull(v) {
  return (typeof v !== 'undefined' && v !== '' && v !== null) ? v : '';
}

export function openURL(url, target){
  try {
    window.open(url, target);
  } catch (error) {console.log(error)}
}

export function openWhats(number){
  try {
    openURL('https://wa.me/+55'+onlyNumber(number), '_blank');
  } catch (error) {console.log(error)}
}

export function isVerificated(verification) {
  try {
    let verifications = JSON.parse(b64_to_utf8(sessionStorage.getItem('verifications')));
    return verifications[verification] ? verifications[verification] : false;
  } catch (error) {
    return false
  }
}

export function openMail(mail) {
  try {
    openURL('mailto:'+mail, '_blank');
  } catch (error) {}
}

export function convertToIsoDate(d){
  return d.replace(" ", "T") + ":00Z";
}

export function revertToIsoDate(d){
  return d.replace("T", " ").replace(":00Z", "");
}

export function displayTime(d){
  return d;
}

export function slugify(str){
  return urlSlug(str);
}

export function canAccessModule(name){
  const moduleSession = JSON.parse(b64_to_utf8(sessionStorage.getItem("module")));
  let ret = false;
  let idx = findObjIntoArrayByProp(moduleSession, 'module', name )
  if(idx !== -1)
    return moduleSession[idx].status === true;
  return ret;
}

export function canCreateNewFromModule(m, s) {
  const moduleSession = JSON.parse(b64_to_utf8(sessionStorage.getItem("module")));
  let ret = false;
  let idx = findObjIntoArrayByProp(moduleSession, 'module', m )
  if(idx !== -1) {
    let l = moduleSession[idx].qtd;
    if(m === 'Usuarios')
      l = l + 1; // PARA TER UM USUÁRIO EXTRA DE SUPORTE

    return l > s;
  }
  return ret;
}
